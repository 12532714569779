<template>
  <div class="container">
    <footer class="py-3 my-4">
      <ul class="nav justify-content-center border-bottom pb-3 mb-3">
        <li class="nav-item">
          <a
            href="/"
            class="px-3 link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
            >{{ $t("nav.link1") }}</a
          >
        </li>
        <li class="nav-item">
          <a
            href="/velo-publicitaire"
            class="px-3 link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
            >{{ $t("nav.link2") }}</a
          >
        </li>
        <li class="nav-item">
          <a
            href="/papier-ensemence"
            class="px-3 link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
            >{{ $t("nav.link3") }}</a
          >
        </li>
        <li class="nav-item">
          <a
            href="/contact"
            class="px-3 link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
            >{{ $t("nav.link4") }}</a
          >
        </li>
      </ul>
      <p class="text-center text-body-secondary">
        Copyright &copy; <span id="year"></span> - Eco Pub
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  mounted() {
    const year = new Date().getFullYear();
    document.getElementById("year").textContent = year;
  },
};
</script>

<style></style>
